.features-split {
  margin: 0 auto;
  .section-inner {
    padding-top: $features-split--padding-t__mobile;
    padding-bottom: $features-split--padding-b__mobile;
  }

  .section-header {
    padding-bottom: $features-split-header--padding__mobile;
  }
  img {
    width: 50%;
    min-width: 13rem;
    margin: 0 auto;
  }

  p {
    text-align: left;
    width: 100%;
    margin: 1rem auto;
  }
}

@include media(">medium") {
  .features-split {
    .section-inner {
      padding-top: $features-split--padding-t__desktop;
      //   padding-bottom: $features-split--padding-b__desktop;
    }

    .section-header {
      padding-bottom: $features-split-header--padding__desktop;
    }
  }
}

@include media("<=small") {
  .features-split {
    width: 100%;
    margin: 0 auto;
    img {
      margin: 0 auto;
      width: 90%;
      min-width: 13rem;
    }
    p {
      width: 95%;
      text-align: center;
    }
  }
}
