.events-section {
  text-align: center;
  margin: 3rem auto;
  background-color: lightgray;
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 55%;
  display: flex;
  justify-content: center;
  .events-section-inner {
    h3 {
      text-align: center;
    }
    p {
      margin: 1rem auto;
      width: 90%;
      max-width: 1000px;
      font-weight: 450;

      &:last-child {
        margin-bottom: 5rem;
      }
    }

    a {
      color: dodgerblue;

      &:hover {
        cursor: pointer;
      }
    }
    img {
      margin: 0 auto;
      width: 20rem;
    }
  }
}

@include media("<=medium") {
  .events-section {
    text-align: center;
    margin: 0.3rem auto;
    background-color: lightgray;
    border: 1px solid lightgray;
    border-radius: 5px;
    width: 95%;
    display: flex;
    justify-content: center;
    .events-section-inner {
      h3 {
        text-align: center;
      }
      p {
        margin: 1rem auto;
        width: 90%;
        max-width: 1000px;
        font-weight: 450;

        &:last-child {
          margin-bottom: 5rem;
        }
      }
    }
  }
}
