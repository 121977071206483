.split-wrap {
  margin-top: -($split-item--padding-v__mobile / 3.5);

  &:last-of-type {
    margin-bottom: ($split-item--padding-v__mobile / 3.5);
  }

  &:not(:last-of-type) {
    margin-bottom: $split-item--padding-v__mobile / 2;
  }

  .split-item {
    padding-top: 15px;
    // padding-bottom: $split-item--padding-v__mobile / 2;
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;

    .split-item-image {
      position: relative;
      padding-bottom: 5px;
      // margin: 0 auto;
      // width: 100%;

      img,
      svg,
      video {
        width: auto;
        height: auto;
        max-width: 100%;
        overflow: visible;
      }

      &.split-item-image-fill {
        img,
        svg,
        video {
          width: 100%;
        }
      }
    }
  }
}

@include media("<=medium") {
  .split-wrap {
    .split-item {
      &:last-child {
        flex-direction: column-reverse;
      }
      .split-item-content {
        margin-bottom: $split-item--inner-padding-v_mobile;
      }
    }

    &.invert-mobile {
      .split-item {
        .split-item-content {
          order: 1;
          margin-bottom: 0 !important;

          p {
            text-align: center;
          }
        }

        .split-item-image {
          margin: 1rem auto;
          max-width: 16rem;
          height: auto;
        }
      }
    }
  }
}

@include media(">medium") {
  .split-wrap {
    // margin-top: -($split-item--padding-v__desktop / 2);

    &:last-of-type {
      // margin-bottom: -($split-item--padding-v__desktop / 2);
    }

    &:not(:last-of-type) {
      margin-bottom: $split-item--padding-v__desktop / 2;
    }

    .split-item {
      // padding-top: $split-item--padding-v__desktop / 2;
      // padding-bottom: $split-item--padding-v__desktop / 2;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin: 0 auto;

      .split-item-content {
        flex-shrink: 0;
        width: 60%;
      }

      .split-item-image {
        // min-width: ($container--width-sm / 2) - $split-item-image--padding-h;
        max-width: 16rem;
        text-align: center;
        padding: 10px 0;
      }
    }

    &:not(.invert-desktop) .split-item:nth-child(even),
    &.invert-desktop .split-item:nth-child(odd) {
      justify-content: center;
      align-items: flex-start;

      .split-item-content {
        order: 1;
        margin-left: $split-item--inner-padding-h;
        margin-right: auto;
      }
      .split-item-content {
        order: 2;
        margin-left: $split-item--inner-padding-h;
        margin-right: auto;
      }
    }

    &:not(.invert-desktop) .split-item,
    &.invert-desktop .split-item {
      .split-item-image {
        img,
        svg,
        video {
          // margin-left: auto;
          max-width: 16rem;
          height: auto;
        }
      }
    }

    &.align-top {
      .split-item {
        align-items: flex-start;
      }
    }
  }
}

@include media(">large") {
  .container {
    .split-wrap {
      .split-item {
        .split-item-content {
          // margin-top: 2rem;
          max-width: 100%;
          // min-width: 30.5rem;
          // width: ($container--width / 1.4) - $split-item-content--padding-h;

          &:last-child {
            margin: 0 0 5rem 0;
          }
        }

        .split-item-image {
          min-width: ($container--width / 3) - $split-item-image--padding-h;
        }
      }
    }
  }
}
