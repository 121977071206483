.section-how-to-buy {
  background-color: #4682b4;
  padding: 1rem;
}

#howtobuy {
  margin: 1rem auto;
}

.section-inner {
  padding: 5px 0;
}

.how-to-buy {
  //   width: 80%;
  img {
    margin: 0 auto;
    padding-bottom: 0.5rem;
    max-width: 30%;
    min-width: 15rem;

    &:last-child {
      max-width: 30rem;
    }
  }
}

@include media("<=medium") {
  .how-to-buy {
    // width: 90%;
    img {
      margin: 1rem auto;
      padding-bottom: 1rem;
      max-width: 18rem;
      min-width: 10rem;

      &:last-child {
        width: 90%;
        min-width: 10rem;
      }
    }
  }
}

@include media("<=small") {
  .how-to-buy {
    // width: 90%;
    img {
      margin: 1rem auto;
      padding-bottom: 1rem;
      max-width: 90%;
      max-width: 90%;
      min-width: 10rem;

      &:last-child {
        width: 90%;
      }
    }
  }
}
