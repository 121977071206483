.container {
  max-width: 100%;
}

.site-header {
  position: absolute !important;
  background-color: white;
  top: 10;
  width: 100%;
  z-index: 10 !important;
  background: color-bg(header);

  + .site-content {
    .section:first-of-type {
      padding-top: 0;
    }
  }

  .brand {
    margin-right: $header-nav--padding-h;
    min-width: 5rem;
    margin-top: 5px;
  }
}

.site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: $header-height__mobile - 15px;
  // padding-bottom: 1.2rem;
}

.header-nav {
  flex-grow: 1;

  .header-nav-inner {
    display: flex;
    flex-grow: 1;
    white-space: nowrap;
  }

  ul {
    display: flex;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;
    margin-bottom: 0;

    &:first-of-type {
      flex-wrap: nowrap;
      width: 100%;
      // display: flex;
      // justify-content: flex-end;
    }
  }

  #social-right-header {
    justify-content: center;

    li {
      padding: 0;
      min-width: 2rem;

      a {
        margin: 0 0.3rem 0 0.3rem;

        &:hover {
          color: get-color(primary, 3);
        }
      }
    }
  }

  #tigonsanctuarylink {
    font-weight: 700;
    font-size: 100%;
    padding: 8px 11px 8px 11px;
    margin: 9px;
    border: 2px solid get-color(primary, 1);
    border-radius: 5px;
    color: get-color(light, 3);
    background-color: get-color(primary, 1);

    &:hover {
      color: get-color(primary, 1);
      background-color: get-color(light, 3);
    }

    li {
      margin: 2px;
    }
  }

  #tigonstakinglink {
    font-weight: 700;
    font-size: 100%;
    padding: 8px 11px 8px 11px;
    margin: 9px;
    border: 2px solid get-color(alert, error);
    border-radius: 5px;
    color: get-color(alert, error);
    // background-color: get-color(primary, 1);

    &:hover {
      color: get-color(light, 3);
      // border-color: get-color(light, 3);
      background-color: get-color(alert, error);
      cursor: pointer;
    }

    li {
      margin: 2px;
    }
  }

  li {
    + .header-button {
      margin-left: $header-nav--padding-h;
      flex-shrink: 5;
      white-space: nowrap;
    }
  }

  a {
    opacity: 1.1;
    font-size: 102%;
    margin: 0 0.5rem 0 0.5rem;
    line-height: 1.3;

    &:hover {
      color: get-color(light, 3);
      text-shadow: none;
      // text-decoration: underline;
    }
  }

  a:not(.button) {
    display: block;
    @include anchor-aspect(header);
    @include font-weight(header-link);
    text-transform: $link-header--transform;
    padding: 0 $header-nav--padding-h;
    letter-spacing: -0.25px;

    .invert-color & {
      @include anchor-aspect(header, inverse);
    }
  }

  a.button {
    margin-left: $header-nav--padding-h;
  }
}

.header-nav-center:first-of-type {
  flex-grow: 1;
  justify-content: center;
}

.header-nav-right {
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;

  .social-right-header {
    justify-content: space-between;
  }

  // + .header-nav-right {
  //   flex-grow: 0;
  //   flex-wrap: nowrap;
  //   white-space: nowrap;
  // }
}

.social-right-header {
  justify-content: space-between;
}

.header-nav-toggle {
  display: none;
}

@include media("<=largest") {
  .header-nav {
    #social-right-header {
      flex-direction: column;
      justify-content: center;
      // max-width: 3px;

      li {
        // margin: 0px;
        padding: 0 1px 0 1px;
        min-width: 1.8rem;
        a {
          padding: 0.15rem;
        }
      }
    }
    #tigonsanctuarylink {
      font-weight: 550;
      font-size: 90%;
      // padding: 6px 10px 6px 10px;
      margin: 2.5px;
      border: 2px solid get-color(primary, 1);

      li {
        a {
          margin: 0;
        }
      }
    }

    #tigonstakinglink {
      font-weight: 550;
      font-size: 90%;
      // padding: 6px 10px 6px 10px;
      margin: 2.5px;
      border: 2px solid get-color(alert, error);

      li {
        a {
          margin: 0;
        }
      }
    }

    ul:first-of-type {
      font-size: 72%;
      font-weight: 600;
      letter-spacing: -0.3rem;

      li {
        font-weight: 800;
        a {
          margin: 0 0.3rem 0 0.3rem;
        }
      }
    }
  }

  @include media("<=large") {
    .header-nav-toggle {
      display: block;

      // Header navigation when the hamburger is a previous sibling
      + .header-nav {
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 9999;
        background: color-bg(menu-mobile);

        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transition: max-height 0.25s ease-in-out, opacity 0.15s;

        &.is-active {
          opacity: 1;
        }

        .header-nav-inner {
          flex-direction: column;
          padding: $header-nav--padding-v__mobile;
        }

        ul {
          display: block;
          text-align: center;
          min-width: 10rem;

          a:not(.button) {
            display: inline-flex;
            @include anchor-aspect(header-mobile);
            padding-top: $header-nav--padding-v__mobile / 6;
            padding-bottom: $header-nav--padding-v__mobile / 2;
            color: black;
          }
        }

        // #social-list {
        //   ul {
        //     display: flex;
        //     justify-content: center;
        //     flex-wrap: nowrap;
        //     white-space: nowrap;
        //   }
        // }

        a.button {
          margin-left: 0;
          margin-top: $header-nav--padding-v__mobile / 2;
          margin-bottom: $header-nav--padding-v__mobile / 2;
        }
      }
    }
  }

  @include media(">medium") {
    .site-header {
      + .site-content {
        .section:first-of-type {
          padding-top: $header-height__desktop;
        }
      }
    }

    .site-header-inner {
      height: $header-height__desktop;
    }
  }
}

@include media(">1800px") {
  .container {
    justify-content: center;
    text-align: center;
    max-width: 1600px;
    margin: 0 auto;
  }
}

@include media("<=small") {
  .site-header {
    .brand {
      margin-left: 10px;
      width: 90%;
    }
  }
  .header-nav-toggle {
    display: block;
    margin-right: 15px;

    // Header navigation when the hamburger is a previous sibling
    + .header-nav {
      flex-direction: column;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      z-index: 9999;
      background: color-bg(menu-mobile);

      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out, opacity 0.15s;

      &.is-active {
        opacity: 1;
      }

      .header-nav-inner {
        #social-right-header {
          display: inline;
        }
      }
    }
  }
}
