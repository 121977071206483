.team {
  .team-title-container {
    img {
      margin: 0 auto;
      width: 21%;
      padding-bottom: 2rem;
    }
    p {
      padding: 0 2rem 0 2rem;
    }
  }

  .team-info-container {
    max-width: 80%;
  }

  #mobile-justin-image {
    display: none;
    margin: 0 auto;
  }

  #mobile-justin-jon-image {
    display: none;
    margin: 0 auto;
  }

  .team-info-container {
    margin: 1.5rem auto;
    display: flex;
    width: 100%;
    // padding-left: 2rem;

    #display-none {
      display: block;
      margin: 0 auto;
      width: 60%;
      max-width: 40rem;
      height: auto;
      object-fit: contain;
      padding-bottom: 2rem;
    }

    p {
      width: 40%;
      font-size: 90%;
      line-height: 1.2;
      padding-left: 1rem;
      padding: 15px;

      h5 {
        color: black;
        margin: 0;
      }

      span {
        font-weight: bold;
        color: black;
        font-size: 110%;

        &:last-child {
          font-size: 100%;
        }
      }
    }
  }
}

@include media("<medium") {
  .team {
    .team-title-container {
      img {
        margin: 0 auto;
        max-width: 20rem;
        min-width: 7rem;
        padding-bottom: 2rem;
      }
      p {
        text-align: center;
        padding: 0;
      }
    }

    #mobile-justin-image {
      display: block;
      margin: 0 auto;
      width: 70%;
      height: auto;
    }

    #mobile-justin-jon-image {
      display: block;
      margin: 0 auto;
      width: 70%;
      height: auto;
    }

    .team-info-container {
      max-width: 95%;
      margin: 1.5rem auto;
      display: flex;
      width: 95%;
      //   padding-left: 2rem;

      #display-none {
        display: none;
      }

      p {
        width: 95%;
        margin: 0 auto;
        text-align: center;
        line-height: 1.4;
        padding: 0;
      }
    }
  }
}
@include media("<=small") {
  .team {
    .team-title-container {
      img {
        margin: 0 auto;
        min-width: 7rem;
        padding-bottom: 2rem;
      }
      p {
        text-align: center;
        padding: 10px;
      }
    }
  }
}
