.features-tiles {
  .section-inner {
    padding-top: $features-tiles--padding-t__mobile;
    padding-bottom: $features-tiles--padding-b__mobile;
  }

  #welcomeImg {
    max-width: 32.5rem;
    margin: 1rem auto;
  }

  // .section-header {
  //   padding-bottom: $features-tiles-header--padding__mobile;
  // }

  .tiles-wrap {
    @if ($features-tiles-items--padding__mobile != null) {
      //   margin-right: -($features-tiles-items--padding__mobile / 2);
      //   margin-left: -($features-tiles-items--padding__mobile / 2);
      //   margin-top: -($features-tiles-items--padding__mobile / 2);

      &:last-of-type {
        // margin-bottom: -($features-tiles-items--padding__mobile / 2);
      }

      &:not(:last-of-type) {
        // margin-bottom: ($features-tiles-items--padding__mobile / 2);
      }
    }

    &.push-left {
      &::after {
        // flex-basis: $features-tiles-item--width;
        max-width: $features-tiles-item--width;
        @if ($features-tiles-items--padding__mobile != null) {
          padding-left: $features-tiles-items--padding__mobile / 2;
          padding-right: $features-tiles-items--padding__mobile / 2;
        }
      }
    }
  }

  .tiles-item {
    // flex-basis: $features-tiles-item--width;
    max-width: $features-tiles-item--width;
    @if ($features-tiles-items--padding__mobile != null) {
      padding: $features-tiles-items--padding__mobile / 2;
    }
  }

  .tiles-item-inner {
    padding-top: $features-tiles-item--inner-padding-v;
    padding-bottom: $features-tiles-item--inner-padding-v;
    padding-left: $features-tiles-item--inner-padding-h;
    padding-right: $features-tiles-item--inner-padding-h;
  }

  @include media(">medium") {
    .features-tiles {
      .section-inner {
        padding-top: $features-tiles--padding-t__desktop;
        padding-bottom: $features-tiles--padding-b__desktop;
      }

      .section-header {
        // padding-bottom: $features-tiles-header--padding__desktop;
      }

      @if ($features-tiles-items--padding__desktop != null) {
        .tiles-wrap {
          margin-right: -($features-tiles-items--padding__desktop / 2);
          margin-left: -($features-tiles-items--padding__desktop / 2);
          margin-top: -($features-tiles-items--padding__desktop / 2);

          &:last-of-type {
            margin-bottom: -($features-tiles-items--padding__desktop / 2);
          }

          &:not(:last-of-type) {
            margin-bottom: ($features-tiles-items--padding__desktop / 2);
          }

          &.push-left {
            &::after {
              padding-left: $features-tiles-items--padding__desktop / 2;
              padding-right: $features-tiles-items--padding__desktop / 2;
            }
          }
        }

        .tiles-item {
          padding: $features-tiles-items--padding__desktop / 2;
        }
      }
    }
  }
}

@include media("<medium") {
  .features-tiles {
    #welcomeImg {
      width: 80%;
    }
  }
}

@include media("<small") {
  .features-tiles {
    #welcomeImg {
      width: 95%;
    }
    p {
      padding: 15px;
    }
  }
}
