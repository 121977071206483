.oott-site-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 1rem;
    width: 15%;
    min-width: 10rem;
  }

  .tigons-nft-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 2fr));
    grid-gap: 1rem;

    img {
      width: 50%;
      min-width: 10rem;
      margin: 0.25rem auto;

      @include media("<=medium") {
        min-width: 8rem;
      }
    }

    @include media("<=medium") {
      grid-template-columns: repeat(auto-fit, minmax(50%, 1fr));
    }

    @include media("<=small") {
      grid-template-columns: repeat(auto-fit, minmax(1fr, 1fr));
    }

    .nft-container {
      background-color: whitesmoke;
      padding: 0.75rem;
      border-radius: 10px;
      margin: 1rem auto;
      text-align: center;
      justify-self: center;
      width: 30rem;

      @include media("<=small") {
        width: 90%;
      }

      img {
        margin: 1rem auto;
        padding: 0;
        width: 35%;
        min-width: 15rem;

        @include media("<=medium") {
          min-width: 12rem;
        }

        @include media("<=small") {
          min-width: 9rem;
        }
      }

      h4 {
        // padding-bottom: 1rem;
        color: get-color(primary, 3);
        font-size: 85%;
        margin: 0;
        padding: 0;
      }

      p {
        padding-bottom: 5px;
        margin: 0;

        span {
          font-weight: bold;
          font-size: 90%;
          color: chocolate;
        }
      }

      .atts-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
        grid-gap: 0.45rem;
        margin: 0 auto;

        @include media("<=medium") {
          grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
        }

        @include media("<=small") {
          grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
        }

        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;
          border: 1px solid lightgray;
          border-radius: 5px;
          width: 95%;
          margin: 0 auto;
          background-color: rgb(230, 230, 230);
          padding: 0 2.5px;

          p:first-of-type {
            font-size: 70%;
            font-weight: bold;
            text-transform: capitalize;
            color: get-color(primary, 3);
            line-height: 1.3;
            padding-top: 3px;

            &::first-letter {
            }
          }
          p {
            // white-space: nowrap;
            // padding: 2px;
            margin: 0 auto;
            font-size: 16px;
            font-weight: 500;
            color: black;
            line-height: 1.4;
            padding-bottom: 3px;
          }
        }
      }
      @include media("<=small") {
        width: 99%;
      }
    }
  }
}
