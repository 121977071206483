.attributes {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem auto;

  img {
    margin: 1rem auto;
    width: 60%;
    min-width: 13rem;
  }
  p {
    margin: 0px;
    text-align: center;
  }
}

@include media("<=small") {
  .attributes {
    width: 100%;
    img {
      margin: 1rem auto;
      padding-bottom: 0.5rem;
      width: 70%;
      min-width: 11rem;

      &:last-child {
        width: 90%;
      }
    }
    p {
      width: 95%;
      padding: 5px;
    }
  }
}
