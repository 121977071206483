.lore-section {
  .lore-section-inner {
    h3 {
      text-align: center;
    }
    p {
      margin: 1rem auto;
      width: 90%;
      max-width: 1000px;
      font-weight: 450;

      &:last-child {
        margin-bottom: 5rem;
      }
    }
    img {
      margin: 0 auto;
      width: 20rem;
    }
  }
}
