.container,
.container-sm,
.container-xs {
  width: 100%;
  margin: 0 auto;
  // padding-left: $container--padding__mobile;
  // padding-right: $container--padding__mobile;

  @include media(">small") {
    padding-left: $container--padding__desktop / 2;
    padding-right: $container--padding__desktop / 2;
  }
}

.container {
  max-width: $container--width + ($container--padding__desktop * 2);
}

.container-sm {
  max-width: $container--width-sm + ($container--padding__desktop * 2);
}

.container-xs {
  max-width: $container--width-xs + ($container--padding__desktop * 2);
}

[class*="container"] {
  [class*="container"] {
    padding-left: 0;
    padding-right: 0;
  }

  .container-sm {
    max-width: $container--width-sm;
  }

  .container-xs {
    max-width: $container--width-xs;
  }
}
