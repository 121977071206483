.roadmap-section {
  background-color: #4682b4;
}
.roadmap {
  .road-map-img {
    max-width: 40%;
    margin: 1rem auto;
    padding: 1.5rem 0;
  }

  .phase {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;

    .phase-containers {
      display: flex;
      margin: 0 auto;
      margin-bottom: 1.5rem;
      align-items: center;
      justify-content: space-around;

      img {
        margin: 1rem;
        max-width: 45%;
        height: 2rem;
      }

      ul {
        line-height: 95%;
        width: 20rem;

        .strike {
          list-style-type: "\2713\0020";
          text-decoration: line-through;
          text-decoration-thickness: 1px;
        }

        .strike:before {
          // content: "\2713\0020";
        }
      }
    }
  }
}
@include media("<=medium") {
  .roadmap {
    .section-inner {
      .road-map-img {
        min-width: 10rem;
      }

      .phase {
        flex-direction: column;
        align-items: center;

        .phase-containers {
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          text-align: center;
          margin-bottom: 0.25rem;

          img {
            margin: 0px;
            min-width: 8rem;
          }

          ul {
            width: 70%;
            // display: table;
            margin: 1rem auto;
            text-align: left;
            white-space: nowrap;

            li {
              text-align: left;
              width: 80%;
            }
          }
        }
      }
    }
  }
}

@include media("<=small") {
  .roadmap {
    .section-inner {
      .phase {
        .phase-containers {
          ul {
            width: 90%;
            min-width: 16rem;
            white-space: normal;

            li {
              font-size: 85%;
              width: 90%;
            }
          }
        }
      }
    }
  }
}

@include media(">largest") {
  .roadmap,
  .section-inner,
  phase,
  .phase-containers,
  ul,
  li {
    text-align: left;
  }
}
