.metaverse-section {
  .metaverse-section-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    h4 {
      text-align: center;
      max-width: 90%;
    }

    img {
      margin: 0.5rem auto;
      width: 15rem;
      @include media("<=small") {
        width: 10rem;
      }
    }
    .container {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    /* Then style the iframe to fit in the container div with full height and width */
    .responsive-iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 80%;
      //   margin: auto;
      //   display: block;
      @include media("<=small") {
        width: 100%;
        height: 100%;
      }
    }
  }
}
