.container {
  width: 100%;
  max-width: 1600px;
}

.oott-site-header {
  position: sticky !important;
  background-color: white;
  top: 10;
  width: 100%;
  //   max-width: 1600px;
  z-index: 10 !important;
  background: color-bg(header);
  margin: 0 auto;
  //   text-align: center;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;

  + .site-content {
    .section:first-of-type {
      padding-top: 0;
    }
  }

  .brand {
    margin-right: $header-nav--padding-h;
    min-width: 5rem;
    margin: 6px;
  }
}

.oott-site-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  height: $header-height__mobile - 15px;
}

.m-0 {
  min-width: 2.5rem;
}

.oott-header-nav {
  flex-grow: 1;

  .oott-header-nav-inner {
    display: flex;

    flex-grow: 1;
    white-space: nowrap;
    align-items: center;
  }

  ul {
    display: flex;
    align-items: center;
    flex-grow: 1;
    white-space: nowrap;
    margin-bottom: 0;

    &:first-of-type {
      flex-wrap: nowrap;
      width: 90%;
      //   justify-content: center;

      li {
        font-size: 18px;
        margin: 1%;
      }
    }
    &:last-of-type {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 5rem;

      li {
        font-size: 15px;
        margin: 3px 5px 0 0;
        // max-width: 10%;
      }
      img {
        width: 37px;
        height: 30px;
        // min-width: 35px;
        // height: auto;
        border-radius: 50%;
        // margin: 2px;
      }
    }
  }

  li {
    + .header-button {
      margin-left: $header-nav--padding-h;
      flex-shrink: 5;
      white-space: nowrap;
    }
  }

  a {
    opacity: 1.1;
    font-size: 102%;
    margin: 0 0.5rem 0 0.5rem;
    line-height: 1.3;

    &:hover {
      color: get-color(light, 3);
      text-shadow: none;
      // text-decoration: underline;
    }
  }

  a:not(.button) {
    display: block;
    @include anchor-aspect(header);
    @include font-weight(header-link);
    text-transform: $link-header--transform;
    padding: 0 $header-nav--padding-h;
    letter-spacing: -0.25px;

    .invert-color & {
      @include anchor-aspect(header, inverse);
    }
  }

  a.button {
    margin-left: $header-nav--padding-h;
  }
}

.oott-header-nav-center:first-of-type {
  flex-grow: 1;
  justify-content: center;
}

.oott-header-nav-right {
  justify-content: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.oott-header-nav-toggle {
  display: none;
}

// @include media("<=largest") {
//   .oott-header-nav {
//     ul:first-of-type {
//       font-size: 72%;
//       font-weight: 600;
//       letter-spacing: -0.3rem;

//       li {
//         font-weight: 800;
//         a {
//           margin: 0 0.3rem 0 0.3rem;
//         }
//       }
//     }
//   }

@include media("<=medium") {
  .oott-header-nav {
    flex-grow: 1;

    .oott-header-nav-inner {
      display: flex;

      flex-grow: 1;
      white-space: nowrap;
      align-items: center;
    }

    ul {
      display: flex;
      align-items: center;
      flex-grow: 1;
      white-space: nowrap;
      margin-bottom: 0;
      width: 90%;

      &:first-of-type {
        flex-wrap: nowrap;
        width: 100%;
        justify-content: center;

        li {
          font-size: 18px;
          margin: 0px;
        }
      }
      &:last-of-type {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 5rem;
        width: 90%;

        li {
          //   font-size: 15px;
          margin: 0;
          // max-width: 10%;
        }
        img {
          width: 37px;
          min-width: 35px;
          height: auto;
          border-radius: 90%;
          // margin: 2px;
        }
      }
    }
  }
  .oott-header-nav-toggle {
    display: block;
    margin-right: 35px;

    // Header navigation when the hamburger is a previous sibling
    + .oott-header-nav {
      flex-direction: column;
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      z-index: 9999;
      background: color-bg(menu-mobile);

      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out, opacity 0.15s;

      &.is-active {
        opacity: 1;
      }

      .oott-header-nav-inner {
        flex-direction: column;
        padding: $header-nav--padding-v__mobile;
        align-items: center;
      }

      ul {
        display: block;
        text-align: center;
        min-width: 10rem;

        &:last-of-type {
          margin-top: 10px;
          text-align: center;

          li {
            img {
              text-align: center;
              margin: 0 auto;
              width: 40px;
            }
          }
        }

        a:not(.button) {
          display: inline-flex;
          @include anchor-aspect(header-mobile);
          padding-top: $header-nav--padding-v__mobile / 6;
          padding-bottom: $header-nav--padding-v__mobile / 2;
          color: black;
        }
      }

      a.button {
        margin-left: 0;
        margin-top: $header-nav--padding-v__mobile / 2;
        margin-bottom: $header-nav--padding-v__mobile / 2;
      }
    }
  }
}

//   @include media(">medium") {
//     .site-header {
//       + .site-content {
//         .section:first-of-type {
//           padding-top: $header-height__desktop;
//         }
//       }
//     }

//     .site-header-inner {
//       height: $header-height__desktop;
//     }
//   }
// }

//   @include media(">1800px") {
//     .container {
//       justify-content: center;
//       text-align: center;
//       max-width: 1600px;
//       margin: 0 auto;
//     }
//   }
