.cta {
  .section-inner {
    padding-top: $cta--padding-t__mobile;
    padding-bottom: $cta--padding-b__mobile;
  }
}

.cta-slogan {
  margin-bottom: $cta-slogan--padding-v__mobile;

  img {
    margin-bottom: 1.5rem;
  }
}

.cta-action {
  width: 90%;

  form {
    text-align: center;
    button {
      border: 0;
      border-radius: 5px;
      padding: 5px;
      margin: 5px;
      font-size: 80%;
      height: 2.1rem;
      width: 15%;
      min-width: 6rem;
      color: get-color(light, 3);
      background-color: darkblue;
      font-weight: bold;

      &:hover {
        cursor: pointer;
        background-color: purple;
      }
    }
    input,
    #newsletter {
      background-color: get-color(light, 3);
      font-style: italic;
      width: 50%;
      min-width: 50%;
      border: 0;
      border-radius: 5px;
      padding: 5px;
      margin: 5px;
      font-size: 80%;
      height: 2rem;

      &:focus {
        outline: none;
      }
      svg {
        padding: 5px;
      }
      svg:hover {
        cursor: pointer;
      }
    }
    /* Provides styles for status messages */
    .mc__alert {
      text-align: center;
      border: 0;
      border-radius: 5px;
      padding: 5px;
      margin: 5px auto;
      //   height: 2.3rem;
      width: 50%;
      min-width: 6rem;
      font-weight: bolder;
      background-color: rgb(110, 181, 224);

      /* If "sending" this will animate the text.*/
      /* Keyframes for this will be included below.*/
      &--sending {
        color: get-color(light, 1);
        font-weight: 600;
        -webkit-animation: fade-loop 0.8s ease-in-out infinite alternate-reverse
          both;
        animation: fade-loop 0.8s ease-in-out infinite alternate-reverse both;
      }

      /* "success" status styles with text color */
      &--success {
        color: get-color(light, 3);
        font-weight: 600;
      }

      /* "error" status styles*/
      &--error {
        color: rgb(218, 59, 32);
        font-weight: 600;

        /* This addresses any links the error message includes */
        a {
          //   display: none;
          color: get-color(light, 1);
          font-weight: bold;
          transition: 0.3s ease-in-out;
          font-weight: normal;
          display: block;
          margin-top: 5px;
          opacity: 0.7;

          &:hover {
            text-decoration: none;
            opacity: 1;
          }
        }
      }
    }
  }
}

@include media("<=small") {
  .cta-slogan {
    min-width: 100%;

    img {
      width: 100%;
      min-width: 15rem;
    }
  }
  .cta-action {
    margin: 0 auto;
    width: 100%;
    form {
      input,
      #newsletter {
        width: 100%;
      }
    }
  }
}

@include media(">medium") {
  .cta {
    .section-inner {
      padding-top: $cta--padding-t__desktop;
      padding-bottom: $cta--padding-b__desktop;
    }
  }

  .cta-split {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .cta-slogan {
    margin-bottom: $cta-slogan--padding-v__desktop;

    .cta-split & {
      margin-bottom: 0;
      margin-right: $cta-slogan--padding-h__desktop;
    }
  }
}
