.hero {
  .section-inner {
    padding-top: 4.5rem;
    padding-bottom: $hero--padding-b__mobile;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    flex-grow: 5;
  }
}

.hero-inner {
  // Affects only hero full
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  flex-grow: 5;

  > .hero-content + .hero-figure,
  > .hero-figure + .hero-content {
    margin-top: $hero--inner-padding-v_mobile;
  }

  > .hero-figure {
    > a {
      display: inline-flex;
      // prevents stretching
      align-items: center;
      vertical-align: top;
    }
  }
}

.hero-img-container {
  background-color: #4682b4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 65%;
    text-align: center;
    margin: 1rem auto;
    display: block;
  }

  button {
    font-weight: 600;
    font-size: 95%;
    padding: 12px 8px;
    margin: 0 auto;
    margin-top: 1rem;
    border: 2px solid chocolate;
    border-radius: 2.5px;
    color: white;
    background-color: chocolate;
    width: 40%;
    min-width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;

    span {
      color: white;
    }

    &:hover {
      background-color: get-color(light, 3);
      border-color: white;
      cursor: pointer;

      span {
        color: chocolate;
      }
    }

    // &:last-of-type {
    //   min-width: 12rem;
    //   margin: 0.5rem auto;
    //   padding: 12px 8px;
    //   background-color: sandybrown;
    //   border: 2px solid sandybrown;
    // }
  }
}

@include media("<=medium") {
  .hero {
    .section-inner {
      flex-direction: column-reverse;
    }
    .split-wrap {
      .split-item {
        .split-item-content {
          margin-bottom: $hero--inner-padding-v_mobile;
        }
      }
      .hero-inner {
        flex-direction: column-reverse;
      }

      &.invert-mobile {
        .split-item {
          .split-item-image {
            margin-bottom: $hero--inner-padding-v_mobile;
          }
        }
      }
    }
  }
  .hero-img-container {
    img {
      min-width: 16rem;
    }
    button {
      display: block;
      margin-bottom: 2rem;
      min-width: 12rem;
    }
  }
}

@include media("<=small") {
  .hero-img-container {
    img {
      min-width: 70%;
    }
    button {
      margin-top: 0.5rem;
      // margin-bottom: 1rem;
      font-size: 80%;
      min-width: 65%;
    }
  }
}

@include media(">medium") {
  .hero {
    .section-inner {
      padding-top: $hero--padding-t__desktop;
      padding-bottom: $hero--padding-b__desktop;
    }
  }

  .hero-inner {
    // Affects only hero full
    > .hero-content + .hero-figure,
    > .hero-figure + .hero-content {
      margin-top: $hero--inner-padding-v_desktop;
    }
  }
}
