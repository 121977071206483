.login-screen {
  max-width: 1200px;
  margin: 0 auto;
  .login-screen-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;

    a {
      display: flex;
      justify-content: center;
    }

    img {
      width: 40%;
      min-width: 15rem;
      height: auto;
      margin: 1rem auto;
      display: flex;

      &:first-child {
        width: 15%;
        min-width: 9rem;
        margin: 0.5rem;
      }
    }

    p {
      width: 75%;
      line-height: 1.2;
      text-align: center;
      //   font-size: 20px;
    }
  }

  .log-in-success {
    margin: 0;
    color: #4f8a10;
    background-color: #dff2bf;
    background-image: url("https://i.imgur.com/Q9BGTuy.png");
  }

  empty-div {
    height: 0.5rem;
    background: none;
  }

  .error-div {
    display: block;
    height: 0.5rem;
    text-align: center;
    width: 60%;
    margin: 0rem auto;
    margin-bottom: 2.5rem;

    @include media("<=medium") {
      width: 90%;
    }

    .errorMsg {
      border: 1px solid;
      margin: 10px 0px;
      padding: 5px 1px 5px 10px;
      background-repeat: no-repeat;
      background-position: 10px center;
      color: crimson;
      font-size: 80%;
      font-weight: bold;

      background-color: #ffbaba;
      background-image: url("https://i.imgur.com/GnyDvKN.png");

      @include media("<=medium") {
        font-size: 14px;
        padding: 5px 1px 5px 5px;
      }
    }
  }

  .btns-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 0.5rem;

    img {
      .td-logo-svg {
        width: 2rem;
        height: 2rem;
      }
    }

    .sub-text {
      font-size: 14px;
      line-height: 0.99;
      color: gray;
      text-align: center;
      width: 40%;
      margin-top: 2px;
      //   margin-bottom: 2rem;
    }

    p {
      font-size: 14px;
      line-height: 0.97;
      color: gray;
      text-align: center;
      width: 80%;

      a {
        color: dodgerblue;
        font-weight: 600;
      }
    }

    .theta-connect-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    button {
      font-weight: 600;
      font-size: 85%;
      padding: 10px 8px;
      margin: 3px auto;
      border: 2px solid get-color(primary, 1);
      border-radius: 2.5px;
      color: get-color(light, 3);
      background-color: get-color(primary, 1);
      width: 15rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .td-logo-svg {
        width: 1.4rem;
        height: 1.4rem;
        display: inline;
        margin-left: 0.5rem;
      }

      &:hover {
        color: get-color(primary, 1);
        background-color: get-color(light, 3);
        cursor: pointer;
      }

      &:last-child {
        background-color: mediumaquamarine;
        border: 2px solid mediumaquamarine;
        color: white;

        &:hover {
          color: get-color(primary, 1);
          background-color: get-color(light, 3);
          cursor: pointer;

          a {
            color: mediumaquamarine;
          }
        }
        a {
          color: get-color(light, 3);
        }
      }
    }
  }
}

footer {
  display: flex;
  justify-content: center;

  img {
    margin: 4rem 0 1rem 0;
    width: 7.5rem;
  }
}

// @include media("<medium") {
//   .login-screen,
//   .login-screen-header,
//   #members-text {
//     // width: 95%;
//   }
// }

@include media("<=medium") {
  .login-screen {
    // margin: 0;
    .login-screen-header {
      img {
        width: 50%;
        min-width: 12rem;
        height: auto;
        margin: 0.5rem;

        &:first-child {
          width: 25%;
          min-width: 8rem;
        }
      }
      #members-text {
        width: 95%;
        margin: 0 auto;
        text-align: center;
        font-size: 16px;
      }
    }
    .btns-container {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      .theta-connect-btn {
        display: flex;
        flex-direction: column;
        button {
          padding: 9px 8px;
          max-width: 12rem;
        }
        p {
          font-size: 14px;
          line-height: 0.97;
          color: gray;
          text-align: center;
          width: 70%;
          margin-top: 2px;
          margin-bottom: 2rem;
        }
        .sub-text {
          font-size: 14px;
          line-height: 0.97;
          color: gray;
          text-align: center;
          width: 60%;
          margin-top: 2px;
          margin-bottom: 1rem;
        }
      }

      .errorMsg h4 {
        max-width: 97%;
        white-space: nowrap;
        font-size: 16px;
        margin: 5px;
      }

      button {
        padding: 12px 8px;
        max-width: 12rem;
      }
    }
    footer {
      display: flex;
      justify-content: center;

      img {
        margin-top: 0.75rem;
        width: 6rem;
      }
    }
  }
}

@include media("<=small") {
  .login-screen {
    .login-screen-header {
      img {
        &:first-child {
          width: 25%;
          min-width: 6rem;
        }
      }
    }

    .error-div {
      display: block;
      height: 0.5rem;
      text-align: center;
      width: 95%;
      margin: 0rem auto;
      margin-bottom: 1.5rem;

      .errorMsg {
        margin: 10px 0px;
        padding: 5px 1px 5px 10px;
        background-repeat: no-repeat;
        background-position: 10px center;
        color: crimson;
        font-size: 85%;
        background-image: none;
        line-height: 1.3;
      }
    }

    .btns-container {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      .theta-connect-btn {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        button {
          padding: 9px 8px;
          max-width: 12rem;
        }
        p {
          font-size: 14px;
          line-height: 0.97;
          color: gray;
          text-align: center;
          width: 70%;
          margin-top: 2px;
          margin-bottom: 2rem;
        }
        .sub-text {
          font-size: 14px;
          line-height: 0.97;
          color: gray;
          text-align: center;
          width: 90%;
          margin-top: 2px;
          margin-bottom: 1rem;
        }
      }

      .errorMsg h4 {
        max-width: 97%;
        white-space: nowrap;
        font-size: 16px;
        margin: 5px;
      }

      button {
        padding: 12px 8px;
        max-width: 12rem;
      }
    }
  }
}

footer {
  display: flex;
  justify-content: center;

  img {
    margin: 1rem 0 1rem 0;
    width: 7.5rem;
  }
}

footer {
  display: flex;
  justify-content: center;

  img {
    margin: 1rem 0 1rem 0;
    width: 7.5rem;
  }
}
