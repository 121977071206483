.about-container {
  img {
    margin: 0 auto;
  }

  #about-jon-text-image {
    width: 40%;
  }

  #about-jon-desktop-image {
  }

  #about-jon-mobile-image {
    display: none;
  }
}

@include media("<=medium") {
  .about-container {
    #about-jon-text-image {
      max-width: 22rem;
      min-width: 12rem;
      margin: 1rem auto;
    }

    #about-jon-desktop-image {
      display: none;
    }

    #about-jon-mobile-image {
      display: block;
    }
  }
}
