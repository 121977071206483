.tigon-art-container {
  margin-bottom: 0.5rem;
  .tigon-art-title-container {
    display: flex;
    flex-direction: column;

    img {
      margin: 0.5rem auto;
      width: 50%;
    }
    p {
      text-align: center;
    }
  }

  .tigon-art-pics-container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  img {
    width: 22%;
    min-width: 13rem;
    margin: 0.25rem;
  }
}

@include media("<=medium") {
  .tigon-art-container {
    .tigon-art-title-container {
      img {
        width: 50%;
      }
      p {
        width: 95%;
        padding: 5px;
      }
    }

    .tigon-art-pics-container {
      flex-direction: column;

      img {
        width: 70%;
        margin: 0.5rem auto;
      }
    }
  }
}
